<template>
  <div class="page--home">
    <v-container fluid>
      <v-row class="mt-10">
        <v-col class="text-center">
          <h1
            class="text-h3 grey--text text--darken-3 font-weight-light text-uppercase"
          >
            Olá, {{ $user.fullName }}
          </h1>
          <p class="mt-4 grey--text text--darken-3 font-weight-light">
            Escolha um das opções de menu e acesse
            <br />
            as funcionalidades do {{ $configurations.app.alias }}
          </p>
        </v-col>
      </v-row>
      <div
        class="d-flex flex-column justify-space-between align-center"
        v-if="!$isUserAdmin()"
      >
        <div class="d-flex text-center justify-center">
          <NavigationMapComponent></NavigationMapComponent>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import NavigationMapComponent from "components/NavigationMapComponent.vue";

export default {
  name: "Home",
  components: {
    NavigationMapComponent,
  },
};
</script>
