var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "content": ""
    }
  }, [_c('v-container', {
    staticClass: "mb-5",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "center"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "concluido"
  }), _c('strong', [_vm._v("Concluído")])]), _c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "pendente"
  }), _c('strong', [_vm._v("Pendente")])])])], 1), _vm._l(_vm.steps, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "d-inline"
    }, [!(item.permission == 'profissional.termo.aceitar' && item.complete) ? _c('div', [_c('v-card', {
      class: item.complete ? 'filled' : 'outlined',
      on: {
        "click": function ($event) {
          return _vm.redirectToUrl(item);
        }
      }
    }, [_c('v-card-title', {
      staticClass: "card-title"
    }, [_c('v-tooltip', {
      attrs: {
        "disabled": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            staticClass: "card-icon",
            attrs: {
              "dark": "",
              "x-large": ""
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(item.icon) + " ")])];
        }
      }], null, true)
    }), _vm._v(" " + _vm._s(_vm.formatIndex(i)) + " ")], 1), _c('v-card-title', {
      staticClass: "text-h6",
      domProps: {
        "innerHTML": _vm._s(_vm.formatText(item.text))
      }
    })], 1), i + 1 != _vm.steps.length ? _c('v-tooltip', {
      attrs: {
        "disabled": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on,
              attrs = _ref2.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": "primary",
              "large": ""
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" mdi-arrow-right-thick ")])];
        }
      }], null, true)
    }) : _vm._e()], 1) : _vm._e()]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }