var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page--home"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "text-h3 grey--text text--darken-3 font-weight-light text-uppercase"
  }, [_vm._v(" Olá, " + _vm._s(_vm.$user.fullName) + " ")]), _c('p', {
    staticClass: "mt-4 grey--text text--darken-3 font-weight-light"
  }, [_vm._v(" Escolha um das opções de menu e acesse "), _c('br'), _vm._v(" as funcionalidades do " + _vm._s(_vm.$configurations.app.alias) + " ")])])], 1), !_vm.$isUserAdmin() ? _c('div', {
    staticClass: "d-flex flex-column justify-space-between align-center"
  }, [_c('div', {
    staticClass: "d-flex text-center justify-center"
  }, [_c('NavigationMapComponent')], 1)]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }