<template>
  <div content>
    <v-container fluid class="mb-5">
      <v-row class="center">
        <div class="box">
          <div class="concluido"></div>
          <strong>Concluído</strong>
        </div>
        <div class="box">
          <div class="pendente"></div>
          <strong>Pendente</strong>
        </div>
      </v-row>
    </v-container>
    <div class="d-inline" v-for="(item, i) in steps" :key="i">
      <!-- caso o usuário já tenha aceitado o termo, ele é removido da tela -->
      <div v-if="!(item.permission == 'profissional.termo.aceitar' && item.complete)">
        <v-card :class="item.complete ? 'filled' : 'outlined'" @click="redirectToUrl(item)">
          <v-card-title class="card-title">
            <v-tooltip disabled>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="card-icon" dark x-large v-bind="attrs" v-on="on">
                  {{ item.icon }}
                </v-icon>
              </template>
            </v-tooltip>
            {{ formatIndex(i) }}
          </v-card-title>
          <v-card-title class="text-h6" v-html="formatText(item.text)">
          </v-card-title>
        </v-card>
        <v-tooltip disabled v-if="i + 1 != steps.length">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" large v-bind="attrs" v-on="on">
              mdi-arrow-right-thick
            </v-icon>
          </template>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationMapItem } from "~/commons/classes";

export default {
  name: "NavigationMapComponent",
  data: () => ({
    // Exemplo: Para que a o item 'Termo de Confidencialidade e Sigilo' fique como concluído, é
    // necessário que o usuário possua a permissão do próximo item, que seria 'Comprovar Ação de
    // Capacitação' (propriedade 'next'), pois caso ele possua a permissão do termo, quer dizer
    // que ele concluiu o passo anterior
    steps: [
      new NavigationMapItem({
        icon: "mdi-account-check-outline",
        text: "Autenticar Usuário",
        complete: true,
      }),
      new NavigationMapItem({
        icon: "mdi-file-document-outline",
        text: "Atualizar Dados Cadastrais",
        url: "/app/profissional/atualizar-dados-cadastrais",
        next: "profissional.termo.aceitar",
        permission: "profissional.dadoscadastrais.atualizar",
      }),
      new NavigationMapItem({
        icon: "mdi-shield-account-variant-outline",
        text: "Termo de Confidencialidade e Sigilo",
        url: "/app/profissional/consentimento-dados-pessoais",
        next: "profissional.acaocapacitacao.comprovar",
        permission: "profissional.termo.aceitar",
      }),
      new NavigationMapItem({
        icon: "mdi-check",
        text: "Comprovar Ação de Capacitação",
        url: "/app/profissional/comprovar-acao-de-capacitacao",
        next: "profissional.acaocapacitacao.listar",
        permission: "profissional.acaocapacitacao.comprovar",
      }),
    ],
  }),
  methods: {
    formatIndex(index) {
      return index + 1 > 10 ? index + 1 : "0" + (index + 1);
    },
    formatText(text) {
      let textHTML = text.split(" ").map((v) => {
        return v.length > 2 ? v.concat("</br>") : v.concat(" ");
      });
      let textFormatted = "";
      textHTML.forEach((value) => {
        textFormatted += value;
      });
      return textFormatted;
    },
    redirectToUrl(item) {
      // Se o item não estiver marcado como completo
      !item.complete &&
        // e se o usuário possuir a permissão de ir para o item do mapa
        this.$user.permissions.some((p) => p.includes(item.permission)) &&
        // redireciona o usuário para a tela do item
        this.$router.push({ path: item.url });
    },
  },
  mounted() {
    const possuiPermissaoCadastrar = this.$user.permissions.some((p) =>
      p.includes("profissional.acaocapacitacao.comprovar")
    );
    // Caso o usuário possua a permissão de cadastrar ação de capacitação
    if (possuiPermissaoCadastrar) {
      this.steps.forEach((step) => {
        // Preenche todas os cards anteriores como concluídos
        step.complete = step.next != "profissional.acaocapacitacao.listar";
      });
      // Consulta se o usuário já cadastrou alguma ação de capacitação
      this.$listarComprovacoesPorUsuario(this.$user.username).then(
        ({ data }) => {
          // Busca o card referente ao fluxo de cadastrar ação de capacitação
          this.steps.find(
            (p) => p.next == "profissional.acaocapacitacao.listar"
          ).complete = data.comprovacoes.length > 0;
        }
      );
      // Caso não possua a permissão de cadastrar
    } else {
      this.$user.permissions.forEach((permission) => {
        this.steps.forEach((step) => {
          // preenche de acordo com as permissões do usuário
          if (step.next == permission) {
            // setando os itens na tela como concluídos
            step.complete = true;
          }
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
div[content] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.center {
  justify-content: center;
  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    div {
      width: 2em;
      height: 2em;
      margin: 1em;
      border-radius: 0.3em;
      &.concluido {
        background-color: var(--primary-button-color);
      }
      &.pendente {
        background-color: #fff;
        border: 1px solid var(--primary-link-color);
      }
    }
  }
}

.v-card {
  display: inline-block;
  border-radius: 0.7em;
  margin: 1em;
  &.filled {
    background: var(--primary-button-color) !important;
    color: #fff;
    cursor: auto;
  }
  &.outlined {
    .card-icon {
      color: var(--primary-link-color);
    }
    border: thin solid currentColor;
    border-color: var(--primary-link-color);
    background: #fff !important;
    color: var(--primary-link-color);
  }
  &.outlined:hover {
    cursor: pointer;
    opacity: 50%;
    transition: 1000ms;
  }
  .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .text-h6 {
    white-space: nowrap;
    text-align: initial;
  }
}
</style>
